
document.addEventListener('turbolinks:visit, turbolinks:click', function () {
  firstLoad = false

  // Clean up layoutHelpers
  if (layoutHelpers) {
    layoutHelpers.destroy({ removeStyles: false })
  }
});

// Global config
//

toastr.options = {
  "closeButton": false,
  "debug": false,
  "newestOnTop": false,
  "progressBar": false,
  "positionClass": "toast-bottom-center",
  "preventDuplicates": true,
  "onclick": null,
  "showDuration": "100",
  "hideDuration": "1000",
  "timeOut": "4000",
  "extendedTimeOut": "60000",
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "slideDown",
  "hideMethod": "slideUp"
};

$.fn.queryBuilder.regional['fr'] = {
  "__locale": "French (fr)",
  "__author": "Damien \"Mistic\" Sorel, http://www.strangeplanet.fr",
  "add_rule": "Ajouter une règle",
  "add_group": "Ajouter un groupe",
  "delete_rule": "Supprimer",
  "delete_group": "Supprimer",
  "conditions": {
    "AND": "ET",
    "OR": "OU"
  },
  "operators": {
    "equal": "est égal à ",
    "not_equal": "est différent de",
    "in": "dans",
    "not_in": "pas dans",
    "less": "inférieur",
    "less_or_equal": "inférieur ou égal",
    "greater": "supérieur",
    "greater_or_equal": "supérieur ou égal",
    "within_day": "est dans les 24h",
    "between": "est entre",
    "not_between": "n'est pas entre",
    "begins_with": "commence par",
    "not_begins_with": "ne commence pas par",
    "contains": "contient",
    "not_contains": "ne contient pas",
    "ends_with": "finit par",
    "not_ends_with": "ne finit pas par",
    "is_empty": "est vide",
    "is_not_empty": "n'est pas vide",
    "is_yes": "Oui",
    "is_no": "Non",
    "is_null": "est nul",
    "is_not_null": "n'est pas nul",
    "equal_today": "égale à aujourd'hui",
    "sup_today": "supérieure à aujourd'hui",
    "inf_today": "inférieure à aujourd'hui",
    "sup_equal_today": "supérieure ou égale à aujourd'hui",
    "inf_equal_today": "inférieure ou égale à aujourd'hui",
    "equal_yesterday": "égale à hier",
    "equal_this_week": "égale à cette semaine",
    "equal_this_month": "égale à ce mois-ci",
    "equal_this_year": "égale à cette année",
    "last_week": "est durant la semaine dernière",
    "last_month": "est durant le mois dernier",
    "last_year": "est durant l'année dernière",
    "before_this_week": "est avant cette semaine",
    "before_this_month": "est avant ce mois-ci",
    "before_this_year": "est avant cette année",
    "more_than_week": "date de plus de 1 semaine",
    "more_than_month": "date de plus de 1 mois",
    "more_than_year": "date de plus de 1 an",
    "donnee_greater": "supérieur à",
    "donnee_greater_or_equal": "supérieur ou égal à",
    "donnee_less": 'inférieur à',
    "donnee_less_or_equal": "inférieur ou égal à",
    "carac_donnee_greater": "a un nombre de caractères supérieur à",
    "carac_donnee_greater_or_equal": "a un nombre de caractères supérieur ou égal à",
    "carac_donnee_less": 'a un nombre de caractères inférieur à',
    "carac_donnee_less_or_equal": "a un nombre de caractères inférieur ou égal à",
    "mot_donnee_greater": "a un nombre de mots supérieur à",
    "mot_donnee_greater_or_equal": "a un nombre de mots supérieur ou égal à",
    "mot_donnee_less": 'a un nombre de mots inférieur à',
    "mot_donnee_less_or_equal": "a un nombre de mots inférieur ou égal à"

  },
  "errors": {
    "no_filter": "Aucun filtre sélectionné",
    "empty_group": "Le groupe est vide",
    "radio_empty": "Pas de valeur selectionnée",
    "checkbox_empty": "Pas de valeur selectionnée",
    "select_empty": "Pas de valeur selectionnée",
    "string_empty": "Valeur vide",
    "string_exceed_min_length": "Doit contenir au moins {0} caractères",
    "string_exceed_max_length": "Ne doit pas contenir plus de {0} caractères",
    "string_invalid_format": "Format invalide ({0})",
    "number_nan": "N'est pas un nombre",
    "number_not_integer": "N'est pas un entier",
    "number_not_double": "N'est pas un nombre réel",
    "number_exceed_min": "Doit être plus grand que {0}",
    "number_exceed_max": "Doit être plus petit que {0}",
    "number_wrong_step": "Doit être un multiple de {0}",
    "number_between_invalid": "Valeurs invalides, {0} est plus grand que {1}",
    "datetime_empty": "Valeur vide",
    "datetime_invalid": "Fomat de date invalide ({0})",
    "datetime_exceed_min": "Doit être après {0}",
    "datetime_exceed_max": "Doit être avant {0}",
    "datetime_between_invalid": "Valeurs invalides, {0} est plus grand que {1}",
    "boolean_not_valid": "N'est pas un booléen",
    "operator_not_multiple": "L'opérateur \"{1}\" ne peut utiliser plusieurs valeurs"
  },
  "invert": "Inverser",
  "NOT": "NON"
};

$.fn.datepicker.dates['fr'] = {
  days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
  daysShort: ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'],
  daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
  months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
  monthsShort: ['Janv.', 'Févr.', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'],
  today: "Aujourd\'hui",
  clear: "Effacer",
  format: "dd/mm/yyyy",
  locale: 'fr',
  titleFormat: "MM yyyy",
  /* Leverages same syntax as 'format' */
  weekStart: 1
};

flatpickr.l10ns.fr = {
  firstDayOfWeek: 1,
  weekdays: {
    shorthand: ["dim", "lun", "mar", "mer", "jeu", "ven", "sam"],
    longhand: [
      "dimanche",
      "lundi",
      "mardi",
      "mercredi",
      "jeudi",
      "vendredi",
      "samedi",
    ]
  },

  months: {
    shorthand: [
      "janv",
      "févr",
      "mars",
      "avr",
      "mai",
      "juin",
      "juil",
      "août",
      "sept",
      "oct",
      "nov",
      "déc",
    ],
    longhand: [
      "janvier",
      "février",
      "mars",
      "avril",
      "mai",
      "juin",
      "juillet",
      "août",
      "septembre",
      "octobre",
      "novembre",
      "décembre",
    ],
  },
  ordinal: nth => {
    if (nth > 1) return "";
    return "er";
  },
  rangeSeparator: " au ",
  weekAbbreviation: "Sem",
  scrollTitle: "Défiler pour augmenter la valeur",
  toggleTitle: "Cliquer pour basculer",
  time_24hr: true,
};

// Toasts
//

document.addEventListener('turbolinks:before-cache', function () {
  if (document.body.classList.contains('toast-bottom-center')){
    $('.toast-bottom-center')
      .hide()
      .attr('aria-hidden', 'true');
  }
});

// Tooltips and Modals
//

document.addEventListener('turbolinks:load', function () {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();
  $("input[data-role=tagsinput], select[multiple][data-role=tagsinput]").tagsinput();

});

document.addEventListener('turbolinks:visit', function () {
  $('[data-toggle="tooltip"], [data-toggle="popover"]').off();

});

document.addEventListener('turbolinks:before-cache', function () {
  $('[data-toggle="tooltip"]').tooltip('hide');
  $('[data-toggle="popover"]').tooltip('hide');
  $('[data-toggle="modal"]').modal('hide');

  if (document.body.classList.contains('modal-open')) {
    $('.modal')
      .hide()
      .removeAttr('aria-modal')
      .attr('aria-hidden', 'true');
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
  }
});

// Global listeners
//

$(document).on('ajax:before', '[data-remote]', function () {
  Turbolinks.clearCache();
});



// Loader
//

$(document).on("turbolinks:load, turbolinks:before-cache", function () {
  $(".loadingme").each(function () {
    $(this).unblock();
  });
});

$(document).on("turbolinks:click", function () {
  $(".loadingme").each(function () {
    var loader = '<div style= " z-index: 1000;" class="position-fixed top-50 start-50 translate-middle spinner-grow text-primary" role="status" aria-hidden="true"></div>';

    $(this).block({
      message: loader,
      css: {
        backgroundColor: 'transparent',
        border: '0'
      },
      overlayCSS: {
        backgroundColor: '#fff',
        opacity: 0.8
      }
    });
  });
});

// Plugins
//

document.addEventListener('turbolinks:load', function () {
  $.bootstrapSortable({ applyLast: true });

  $('.datepicker').datepicker({
    language: 'fr',
    locale: 'fr',
    format: "dd/mm/yyyy"
  });

  $('.bdatetiLLmepicker').bootstrapMaterialDatePicker({
    weekStart: 1,
    format: 'DD/MM/YYYY HH:mm',
    lang: 'fr',
    shortTime: false,
    switchOnClick: true,
    nowButton: true,
    minDate: new Date(),
    cancelText: 'ANNULER',
    nowText: 'MAINTENANT'
  });

  $('.btimepickerJJ').bootstrapMaterialDatePicker({
    weekStart: 1,
    format: 'HH:mm',
    date: false,
    switchOnClick: true,
    lang: 'fr',
    shortTime: false,
    nowButton: true,
    cancelText: 'ANNULER',
    nowText: 'MAINTENANT'
  });

  $('.bdatepicker').flatpickr({
    altInput: true,
    altFormat: "l d M Y",
    minDate: "today",
    weekNumbers: false,
    locale: 'fr',
    dateFormat: "d/m/Y"
  });

  $('.bdatefreepicker').flatpickr({
    altInput: true,
    altFormat: "l d M Y",
    weekNumbers: false,
    locale: 'fr',
    dateFormat: "d/m/Y"
  });

  $('.bdatetimepicker').flatpickr({
    altInput: true,
    enableTime: true,
    time_24hr: true,
    altFormat: "l d M Y à H:i",
    minDate: "today",
    weekNumbers: false,
    locale: 'fr',
    dateFormat: "d/m/Y H:i:S"
  });

  $('.bdatetimefreepicker').flatpickr({
    altInput: true,
    enableTime: true,
    time_24hr: true,
    altFormat: "l d M Y à H:i",
    weekNumbers: false,
    locale: 'fr',
    dateFormat: "d/m/Y H:i:S"
  });

  $('.btimepicker').flatpickr({
    altInput: true,
    enableTime: true,
    noCalendar: true,
    time_24hr: true,
    altFormat: "H:i",
    minDate: "today",
    weekNumbers: false,
    locale: 'fr',
    dateFormat: "H:i"
  });

  $('.selectpicker').selectpicker();

  // Date
  $('#flatpickr-date').flatpickr({
    altInput: true,
    altFormat: "j/m/Y"
  });

  // Time
  $('#flatpickr-time').flatpickr({
    enableTime: true,
    noCalendar: true,
    altInput: true
  });

  // Datetime
  $('#flatpickr-datetime').flatpickr({
    enableTime: true,
    altInput: true
  });

  // Full
  $('#flatpickr-full').flatpickr({
    weekNumbers: false,
    enableTime: true,
    mode: 'multiple',
    minDate: 'today',
    altInput: true
  });

  // Range
  $('#flatpickr-range').flatpickr({
    mode: 'range',
    altInput: true
  });

  // Inline
  $('#flatpickr-inline').flatpickr({
    inline: true,
    altInput: true,
    allowInput: false
  });
});

document.addEventListener('turbolinks:visit', function () {
  //$('.selectpicker').selectpicker('destroy');

  //$('.datepicker').datepicker('destroy');

  //$('.bdatetiLLmepicker, .btimepickerJJ').bootstrapMaterialDatePicker('destroy');

  //$('.bdatepicker, .bdatefreepicker, .bdatetimepicker, .bdatetimefreepicker, .btimepicker, .selectpicker, #flatpickr-date, #flatpickr-time, #flatpickr-datetime, #flatpickr-full, #flatpickr-range, #flatpickr-inline')
   // .flatpickr('destroy');
});
