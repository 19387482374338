// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
import Rails from "@rails/ujs";
window.Rails = Rails;
require("@rails/activestorage").start()
require("channels")

// Include stylesheets
require("../source/scss/main.scss")
require("../source/scss/ecrans.scss")
require("../source/scss/errors.scss")

// Include main file
require("../source/js/initialize")
require("../source/js/main")

// Initialize turbolinks
const Turbolinks = require("turbolinks")

const originalInstallProgressElement = Turbolinks.ProgressBar.prototype.installProgressElement
Turbolinks.ProgressBar.prototype.installProgressElement = function () {
originalInstallProgressElement.call(this)
this.progressElement.classList.add('bg-primary')
}
require("turbolinks").start()
require("../source/ladda")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
