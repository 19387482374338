/**
* Attaches Ladda instances to buttons by given selector.
* You can access the instance using the result array or buttonEl.laddaInstance property.
*
* @returns Array of attached Ladda instances
*/
const attachLadda = function (Ladda, target, options = {}) {
const instances = [];

$(target).each(function () {
// Create Ladda instance
const laddaInstance = Ladda.create(this, options);

// Remove Ladda markup on page leave
const cleanup = function () {
laddaInstance.remove();
document.removeEventListener('turbolinks:visit', cleanup);
}
document.addEventListener('turbolinks:visit', cleanup);

// Store the instance on button
this.laddaInstance = laddaInstance;

// Add the instance to the result array
instances.push(laddaInstance);
});

return instances;
};

// On page load
document.addEventListener('turbolinks:load', function () {
if (!window.Ladda) return;

// Attach Ladda to buttons
attachLadda(window.Ladda, '.ladda-button');

// Handle click events
$('.ladda-button').on('click', function () {
this.laddaInstance.start();
});

// Remove click event listeners on page leave
const cleanup = function () {
$('.ladda-button').off();
document.removeEventListener('turbolinks:visit', cleanup);
}
document.addEventListener('turbolinks:visit', cleanup);
});

module.exports = {
attachLadda
}
